import moment from 'moment';
import { CartItem, createCartItemFromJson } from '../cart/cart-item.model';
import { createMenuContentFromJson, MenuContent } from '../menu/menu.model';
import { createMixedCostFromJson, MajorCurrency, MixedCost } from '../money';
import { CostBreakdown, createCostBreakdownFromJson } from '../order/cost-breakdown.model';
import { OrderId } from '../order/order-id.model';
import { createProjectedHeadcountFromJson, ProjectedHeadcount } from './projected-headcount.model';

export interface IndividualChoice {
  orderGroupId: OrderId;
  budget: MajorCurrency;
  menuContent: MenuContent;
  choiceDeadline: moment.Moment;
  orderDeadline: moment.Moment;
  expectedHeadcount: number | null;
  projectedHeadcount: ProjectedHeadcount;
  extraItems: CartItem[];
  eatersCost: MajorCurrency;
  extraItemsCost: MajorCurrency;
  finalisedAt: moment.Moment | null;
  isSubsidisedChoice: boolean;
  companyName: string;
  eatersCostBreakdown: CostBreakdown;
  advancedBudgeting: boolean;
  cartsAdditionalReferenceRequired: boolean;
  totalEaterTopUpCostMinusRefunds: MixedCost;
}

export function createIndividualChoiceFromJson(json: Partial<IndividualChoice> = {}): IndividualChoice {
  const individualChoice: Partial<IndividualChoice> = {};

  individualChoice.orderGroupId = json.orderGroupId;
  individualChoice.menuContent = createMenuContentFromJson(json.menuContent);
  individualChoice.choiceDeadline = json.choiceDeadline ? moment(json.choiceDeadline) : null;
  individualChoice.orderDeadline = json.orderDeadline ? moment(json.orderDeadline) : null;
  individualChoice.expectedHeadcount = json.expectedHeadcount !== undefined ? json.expectedHeadcount : null;
  individualChoice.projectedHeadcount = createProjectedHeadcountFromJson(json.projectedHeadcount);
  individualChoice.extraItems = (json.extraItems || []).map(createCartItemFromJson);
  individualChoice.eatersCost = json.eatersCost || 0;
  individualChoice.extraItemsCost = json.extraItemsCost || 0;
  individualChoice.finalisedAt = json.finalisedAt ? moment(json.finalisedAt) : null;
  individualChoice.isSubsidisedChoice = !!json.isSubsidisedChoice;
  individualChoice.companyName = json.companyName;
  individualChoice.eatersCostBreakdown = createCostBreakdownFromJson(json.eatersCostBreakdown);
  individualChoice.advancedBudgeting = !!json.advancedBudgeting;
  individualChoice.cartsAdditionalReferenceRequired = !!json.cartsAdditionalReferenceRequired;
  individualChoice.totalEaterTopUpCostMinusRefunds = createMixedCostFromJson(json.totalEaterTopUpCostMinusRefunds);

  return individualChoice as IndividualChoice;
}
