<div
  [class.d-none]="!displayMenu"
  [attr.data-menu-content-id]="menuContent?.id"
  analyticsId="menu-content"
  test-id="menu"
>
  <div
    *ngIf="showAllergenWarning()"
    class="card card--banner text-color-red-1 body-text text-weight-medium mb-large"
    test-id="allergenWarning"
  >
    <i
      class="material-icons mr-small"
      aria-hidden="true"
    >warning</i>
    Please be aware that this restaurant has not supplied allergen information for all of the dishes below
  </div>

  <div class="d-flex flex-column-reverse flex-md-row justify-content-between mb-small">
    <div class="my-x-small">
      <div class="circle-icon circle-icon--secondary mr-small">
        <i
          class="icon icon--small"
          aria-hidden="true"
        >wheat</i>
      </div>
      <span class="standout-text standout-text--bold">Have a food allergy? </span>
      <button
        *ngIf="!showDisclaimer()"
        class="button-link"
        (click)="scrollToHandle('_allergen-disclaimer')"
        test-id="findOutAboutFoodAllergyButton"
      >Find out more</button>
    </div>
    <div
      *ngIf="showFavouriteButton"
      class="d-flex my-x-small cursor-pointer"
      (click)="onToggleFavourite()"
      analyticsId="favouriteButtonContainer"
      [analyticsData]="{ isAddingToFavourites: isFavourited }"
      test-id="favouriteButtonContainer"
    >
      <i
        class="icon text-color-red-1 mr-small"
        test-id="favouriteButtonIcon"
      >
        {{ isFavourited ? 'heart' : 'heart_outline' }}
      </i>
      <button
        class="button-link text-weight-medium text-decoration-none"
        test-id="favouriteButtonText"
      >
        {{ isFavourited ? 'Remove from' : 'Add to' }} favourites
      </button>
    </div>
  </div>
  <div *ngIf="showDisclaimer()">
    <p class="body-text text-color-charcoal-2 mb-small" test-id="menuContentDisclaimer">
      {{ menuContent.disclaimer }}
    </p>
    <p class="mb-large">
      <button
        class="button-link"
        (click)="scrollToHandle('_allergen-disclaimer')"
        test-id="findOutAboutFoodAllergyButton"
      >General allergy information</button>
    </p>
  </div>

  <div
    (scrollSpy)="onScrolledIntoSection($event)"
    [scrollSpyConfig]="{ offsetPx: 50, offsetElement: tabsRef }"
  ><!-- This element is required for sticky, and conveniently holds the scrollSpy handler -->
    <div
      sticky
      stickyAfter="#lostbar"
      (stickied)="onTabsStuck($event)"
      class="menu-content__tabs"
      [class.menu-content__tabs--stuck]="tabsAreStuck"
      id="sticky-menu-tabs"
    >
      <div class="menu-content__tabs-wrapper">
        <div
          *ngIf="filteredSections.length > 1"
          class="tabs tabs--overflow"
          test-id="nav"
          #tabs
        >
          <div class="tabs__wrapper">
            <div
              class="tabs__tab"
              *ngFor="let section of filteredSections; trackBy: trackSectionByTitle"
            >
              <a
                class="tabs__link"
                [class.tabs__link--active]="section.title === activeSectionTitle"
                [class.tabs__link--admin-only]="section.hidden"
                [href]="url && (url + '#' + getSlugForSection(section))"
                (click)="scrollToMenuSection(section, true, $event)"
                test-id="navLinks"
              >
                {{ section.title }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section
      *ngFor="let section of allowedSections; first as first; trackBy: trackSectionByTitle"
      class="mb-5 pt-3 menu-content__section"
      [scrollSpyTarget]="section"
      [class.d-none]="!filteredSections.includes(section)"
      [class.mt-3]="first"
      [scrollHandle]="getSlugForSection(section)"
      scrollOffsetElements="#sticky-menu-tabs,#lostbar"
      test-id="sections"
    >

      <div class="row align-items-end">
        <div
          class="col-12 col-md-auto mr-auto"
          *ngIf="filteredSections.length > 1"
          test-id="sectionTitles"
          e2e-test-id="menuSectionTitles"
        >
          <div class="d-flex">
            <label
              *ngIf="enableSelectingItems"
              class="form-choice form-choice--large-checkbox d-flex"
            >
              <input
                class="form-choice__input"
                type="checkbox"
                [checked]="section.selected === 'all'"
                [indeterminate]="section.selected === 'some'"
                [disabled]="!isSectionSelectable(section)"
                (change)="onToggleSection(section.items)"
                test-id="sectionCheckboxes"
                e2e-test-id="sectionCheckboxes"
              />
              <span
                class="mb-none form-choice__label mr-standard"
                test-id="sectionCheckboxLabels"
              ></span>
            </label>
            <h2
              class="mb-0 heading-3"
              test-id="simpleSectionTitles"
              e2e-test-id="simpleSectionTitles"
            >
              {{ section.title }}
            </h2>
          </div>
        </div>
        <div
          *ngIf="section.hidden"
          class="col-12 col-md-auto mt-2 mt-md-0"
        >
          <span
            class="card-title"
            test-id="hiddenSectionMessage"
          >
            - This section is visible only to you -
          </span>
        </div>
      </div>

      <div
        *ngFor="let item of getAllowedItems(section); let itemIndex=index; trackBy: trackItemsById"
        [class.d-none]="!doesItemMatchFilters(item)"
        test-id="itemContainer"
        analyticsId="menu-item"
        [analyticsData]="{itemId: item.id}"
      >
        <div
          [scrollSpyTarget]="item"
          [scrollHandle]="item.id"
          scrollOffsetElements="#sticky-menu-tabs,#lostbar"
          class="d-flex"
        >
          <div
            *ngIf="enableSelectingItems"
            class="menu-content-layout__tooltip-wrapper d-flex justify-content-center align-items-center"
          >
            <label class="form-choice form-choice--large-checkbox">
              <input
                type="checkbox"
                class="form-choice__input"
                [checked]="item.selected"
                [disabled]="!item.selectable"
                (change)="onToggleItemSelection(item.id)"
                test-id="itemCheckboxes"
              />
              <span class="form-choice__label mr-standard">
                <!-- needed for fake checkbox to show--></span>
            </label>
            <span
              *ngIf="!item.selectable"
              class="menu-content-layout__tooltip tooltip tooltip--right"
            >
              <span class="tooltip__inner">
                This item is above your set budget and so cannot be selected
              </span>
            </span>
          </div>

          <app-custom-item
            *ngIf="isCustomItem(item)"
            [item]="item"
            [selectionEnabled]="enableSelectingItems"
            [deliverabilityProblems]="getNonOverridableItemProblems(item.id)"
            [hidePrices]="hidePrices"
            (imageClick)="onDisplayCustomItem(item)"
            test-id="items"
          >
            <ng-container *ngIf="!actionStyle || actionStyle === 'default'">
              <app-deliverability-flag
                *ngIf="allowAddingNonDeliverableItems"
                [problems]="getDeliverabilityProblems(item.id)"
                test-id="deliverabilityFlags"
              ></app-deliverability-flag>
              <app-item-add-button
                class="ml-2"
                [analyticsItem]="item"
                [analyticsEcommerceEventId]="AnalyticsEcommerceEventIds.MENU_CUSTOM_ITEM_ADD_BUTTON"
                (select)="onDisplayCustomItem(item)"
                test-id="customItemAddButtons"
              ></app-item-add-button>
            </ng-container>
            <!-- No case for "none" -->
          </app-custom-item>
          <app-single-item
            *ngIf="isSingleItem(item)"
            [item]="item"
            [selectionEnabled]="enableSelectingItems"
            [deliverabilityProblems]="getNonOverridableItemProblems(item.id)"
            [hidePrices]="hidePrices"
            (imageClick)="onDisplaySingleItem(item)"
            test-id="items"
          >
            <ng-container *ngIf="!actionStyle || actionStyle === 'default'">
              <app-deliverability-flag
                *ngIf="allowAddingNonDeliverableItems"
                [problems]="getDeliverabilityProblems(item.id)"
                test-id="deliverabilityFlags"
              ></app-deliverability-flag>
              <app-quantity-picker
                [analyticsItem]="item"
                [analyticsEcommerceEventId]="AnalyticsEcommerceEventIds.MENU_ITEM_QUANTITY_PICKER"
                [min]="item.minimumOrderQuantity"
                [max]="canOverrideQuantities ? 0 : item.maximumOrderQuantity"
                (roundedDownToMaxValue)="onSingleItemQuantityRoundedDown(itemIndex, item.maximumOrderQuantity)"
                [ngModel]="getCartQuantity(item)"
                (ngModelChange)="onSetSingleItemQuantity(item, $event)"
                test-id="singleItemQuantityPickers"
              ></app-quantity-picker>
            </ng-container>
            <!-- No case for "none" -->
          </app-single-item>
          <app-item-bundle
            *ngIf="isItemBundle(item)"
            [itemBundle]="item"
            [deliverabilityProblems]="getNonOverridableItemProblems(item.id)"
            [selectionEnabled]="enableSelectingItems"
            [hidePrices]="hidePrices"
            (imageClick)="onDisplayItemBundle(item)"
            (displayItemBundle)="onDisplayItemBundle($event)"
            test-id="items"
            [ngSwitch]="actionStyle"
          >
            <ng-container *ngIf="!actionStyle || actionStyle === 'default'">
              <app-deliverability-flag
                *ngIf="allowAddingNonDeliverableItems"
                [problems]="getDeliverabilityProblems(item.id)"
                test-id="deliverabilityFlags"
              ></app-deliverability-flag>
              <app-item-add-button
                class="ml-2"
                [quantity]="getCartQuantity(item)"
                [analyticsItem]="item"
                [analyticsEcommerceEventId]="AnalyticsEcommerceEventIds.MENU_ITEM_BUNDLE_ADD_BUTTON"
                (select)="onDisplayItemBundle(item)"
                test-id="itemBundleAddButtons"
              ></app-item-add-button>
            </ng-container>
            <!-- No case for "none" -->
          </app-item-bundle>
        </div>

        <hr class="m-0 horizontal-line--dotted">

      </div>
    </section>
  </div>

  <p
    class="mt-standard heading-4"
    scrollHandle="_allergen-disclaimer"
    scrollOffsetElements="#sticky-menu-tabs,#lostbar"
  >Ingredient, Allergy and Calorie Information</p>
  <p
    class="menu-content__allergen-disclaimer"
  >
    Please note that all dishes may contain traces of the following allergens:
    Cereals containing gluten, Crustaceans, Eggs, Fish, Peanuts, Soybeans, Milk,
    Nuts, Celery, Mustard, Sesame, Sulphur dioxide/sulphites, Lupin, Molluscs.
    For any questions regarding the allergen contents of specific dishes please contact us via our <a href="https://help.citypantry.com/hc/en-us/requests/new">contact form</a>.
    All allergen advice is provided by the restaurant and cannot be guaranteed by Just Eat for Business.
  </p>
  <p class="menu-content__calorie-disclaimer">Adults need around 2,000 kcal a day.</p>
</div>

<app-feedback-block
  [class.d-none]="displayMenu"
  icon="sad"
  title="Unfortunately, there are no items available for your selected dietary filters"
  test-id="noItemsAvailable"
></app-feedback-block>

<!--
  ItemModalComponent is reused in various page components to render custom items, the reason being that the custom item
  modal state is managed by ngrx, and single item modals are rendered only using internal component state. If custom
  items are ever implemented for marketplace the 2 modals should be unified and use the same state property
-->
<app-item-modal
  *ngIf="displayedSingleItem"
  [item]="displayedSingleItem"
  (close)="onHideSingleItem()"
  test-id="itemModal"
></app-item-modal>
